html, body, #root, .app {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow: hidden;
}

h1 {
  font-weight: 300;
}


@media only screen and (max-height: 600px) {
  #root {
    overflow-y: auto;
  }
  
}
#bg-video {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
}

.bg-video__overlay {
    background-color: #575757;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.7;
}
.slide img {
    height: 100px;
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
}

@media only screen and (max-width: 640px) {
    .slide img {
        height: 70px;
    }
}
#app {
  padding: 15px;
}

header {
  margin-top: 50px;
  text-align: center;
}

header #logo {
  height: 150px;
  width: auto;
}

header h1 {
  margin-top: 50px;
  color: white;
}

#logo-carousel {
  margin-top: 75px;
}

footer {
  text-align: center;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

footer p {
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 300;
}

footer p strong {
  font-weight: 400;
}

footer p a {
  text-decoration: underline;
  color: white;
}

#emm-link {
  text-align: right;
  padding-right: 20px;
}

@media only screen and (max-width: 640px) {
  header #logo {
    height: 100px;
    width: auto;
  }

  header {
    margin-top: 25px;
  }

  header h1 {
    margin-top: 25px;
    font-size: 1.65em;
  }

  #logo-carousel {
    margin-top: 50px;
  }

  #emm-link {
    text-align: center;
    padding-right: 0px;
  }
}

@media only screen and (max-height: 600px) {
  footer {
    margin-top: 25px;
    padding-bottom: 25px;
    position: inherit;
  }
}

