@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html, body, #root, .app {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow: hidden;
}

h1 {
  font-weight: 300;
}


@media only screen and (max-height: 600px) {
  #root {
    overflow-y: auto;
  }
  
}