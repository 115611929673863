.slide img {
    height: 100px;
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
}

@media only screen and (max-width: 640px) {
    .slide img {
        height: 70px;
    }
}